<template>
  <div>
    <InputWithValidation
      ref="Input"
      v-model="text"
      :name="name"
      :label="label"
      :placeholder="placeholder"
      rules="required"
      :errors="errors"
      :field-classes="errors?.[name]?.length && 'mb-22'"
      :input-classes="resultFocus && ['ucrs-input--focused']"
      @input="onInput"
      @focus="onInputFocus"
      @blur="onInputBlur"
    />
    <span v-if="infoMessage" class="text-sm text-gray">
      Esempio: Via Elia Lombardini, 10, Milano...
    </span>
    <div class="relative mb-4">
      <div
        :class="[
          'absolute w-full bg-white border border-t-0 border-black-light',
          {
            hidden: !results || !(inputFocus || resultFocus),
          },
        ]"
      >
        <div v-if="!results?.length" class="min-h-16 p-4 text-gray">
          Nessun risultato trovato
        </div>
        <div ref="Results">
          <div
            v-for="(it, index) in results"
            :key="`Place_${index}`"
            v-dompurify-html="it.highlightedDescription"
            class="cursor-pointer min-h-16 p-4 text-gray-light group truncate outline-none hover:text-white hover:bg-blue focus:text-white focus:bg-blue"
            tabindex="0"
            @click="onResultClick(it)"
            @keyup="(event) => onResultKeyup(event, it)"
            @focus="resultFocus = true"
            @blur="onResultBlur"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import debounce from 'lodash/debounce'

// Components
import InputWithValidation from '~/components/InputWithValidation.vue'

const ValidationMessages = {
  empty: 'Inserisci un indirizzo per procedere',
  streetNumber: 'Inserisci un numero civico',
  zipCode: 'Inserisci un CAP',
}

function computePlaceDetails(place) {
  const {
    address_components: addressComponents,
    formatted_address: location,
    geometry,
  } = place

  const addressComponentsIndex = {
    city: {
      type: ['locality', 'administrative_area_level_3'],
      name: 'short_name',
    },
    country: {
      type: 'country',
      name: 'short_name',
    },
    street: {
      type: 'route',
      name: 'short_name',
    },
    streetNumber: {
      type: 'street_number',
      name: 'short_name',
    },
    zipCode: {
      type: 'postal_code',
      name: 'short_name',
    },
    province: {
      type: 'administrative_area_level_2',
      name: 'long_name',
    },
    provinceInitials: {
      type: 'administrative_area_level_2',
      name: 'short_name',
    },
  }

  const fields = Object.keys(addressComponentsIndex)
  const infos = {}

  for (const it of addressComponents) {
    const { types } = it

    for (const field of fields) {
      const addressField = addressComponentsIndex[field]

      if (
        Array.isArray(addressField.type) &&
        types.some((it) => addressField.type.includes(it))
      ) {
        infos[field] = it[addressField.name]
        continue
      }

      if (types.includes(addressField.type)) {
        infos[field] = it[addressField.name]
      }
    }
  }

  const lat = geometry.location.lat()
  const lon = geometry.location.lng()

  return {
    ...infos,
    location,
    lat,
    lon,
  }
}

export default {
  components: {
    InputWithValidation,
  },

  props: {
    apiKey: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: 'Indirizzo:',
    },

    name: {
      type: String,
      default: 'googlemapsautocomplete',
    },

    placeholder: {
      type: String,
      default: 'Via Elia Lombardini, 10, Milano...',
    },

    requiredFields: {
      type: Array,
      default: null,
    },

    debounce: {
      type: Number,
      default: 600,
    },
  },

  data() {
    return {
      errors: null,
      googleMaps: null,
      googleMapsAutocompletePlace: null,
      googleMapsAutocompleteResults: null,
      googleMapsAutocompleteService: null,
      googleMapsPlacesService: null,
      inputFocus: false,
      place: null,
      resultFocus: false,
      results: null,
      text: null,
      infoMessage: false,
    }
  },

  // We have to do this here and not in mounted because otherwise the event will
  // be first triggered once and then successfully debounced.
  created() {
    this.onInput = debounce(this.onInput, this.debounce)
    this.onResultBlur = debounce(this.onResultBlur, 200)
  },

  async mounted() {
    this.googleMaps = await this.$googleMaps.load(['places'], this.apiKey)
    this.googleMapsAutocompleteService = new this.googleMaps.AutocompleteService()
    this.googleMapsPlacesService = new this.googleMaps.PlacesService(
      this.$refs.Results
    )
  },

  methods: {
    onInput(payload) {
      if (!payload) {
        this.reset()
        return
      }

      this.infoMessage = false

      const input = payload.trim().toLowerCase().replace(/\s+/g, ' ')
      const inputSplitted = input.split(' ')

      switch (true) {
        case inputSplitted.length >= 2 && inputSplitted[1].length >= 2:
          this.search(input)
          break

        case inputSplitted.length === 1:
        case inputSplitted[1].length <= 1:
          this.reset()
          this.infoMessage = true
          break
      }
    },

    search(input) {
      this.googleMapsAutocompleteService.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: 'it' },
          language: 'it',
          types: ['address'],
        },
        this.onAutocompletePlacePredictions
      )
    },

    onAutocompletePlacePredictions(results, status) {
      const { ZERO_RESULTS, OK } = this.googleMaps.PlacesServiceStatus

      switch (true) {
        case status === ZERO_RESULTS:
          this.results = []
          return

        case status !== OK:
          // eslint-disable-next-line no-console
          console.error(status)
          return
      }

      this.googleMapsAutocompleteResults = results

      this.results = results.map((it) => {
        const {
          place_id: placeId,
          description,
          matched_substrings: matches,
        } = it

        const highlightRegex = matches
          .map((match) => description.substr(match.offset, match.length))
          .join('|')
        const descriptionReg = new RegExp(highlightRegex, 'g')

        const highlightedDescription = description.replace(
          descriptionReg,
          '<span class="text-black-light font-bold group-hover:text-white group-focus:text-white">$&</span>'
        )

        return {
          placeId,
          description,
          highlightedDescription,
        }
      })
    },

    onResultClick({ placeId }) {
      this.reset()
      this.googleMapsPlacesService.getDetails(
        {
          placeId,
          fields: ['address_components', 'formatted_address', 'geometry'],
          language: 'it',
        },
        this.onPlaceDetails
      )
    },

    onPlaceDetails(place) {
      // We update the text in input with the formatted address from Google Maps.
      this.googleMapsAutocompletePlace = place

      const placeDetails = computePlaceDetails(place)
      this.place = placeDetails
      this.text = placeDetails.location

      this.$emit('place', this.place)
    },

    validate() {
      const { name, place } = this

      if (!place) {
        const errors = { [name]: [ValidationMessages.empty] }
        this.errors = errors

        return { valid: false, errors }
      }

      const { requiredFields } = this

      for (const it of requiredFields) {
        if (place[it]) {
          continue
        }

        const errors = { [name]: [ValidationMessages[it]] }

        this.errors = errors

        return { valid: false, errors }
      }

      return { valid: true }
    },

    onResultBlur() {
      const { Results } = this.$refs
      const resultFocus = Results.contains(document.activeElement)

      this.resultFocus = resultFocus
    },

    onResultKeyup(event, result) {
      if (event.code === 'Tab') {
        this.text = result.description
      }

      if (event.code !== 'Enter') {
        return
      }

      this.onResultClick(result)
    },

    onInputFocus() {
      this.inputFocus = true
      this.errors = null
    },

    onInputBlur() {
      setTimeout(() => {
        this.inputFocus = false
      }, 200)
    },

    reset() {
      this.results = null
      this.errors = null
      this.infoMessage = false
    },
  },
}
</script>
