<template>
  <div class="text-xs font-sans">
    <p class="mb-2">Note:</p>
    <ol :class="{ 'list-decimal list-inside': numbers }">
      <li
        v-if="evaluation"
        id="foot-evaluation"
        role="link"
        tabindex="0"
        class="mb-2 cursor-pointer"
        @click="scrollBack('foot-evaluation-ref')"
        @keyup.enter="scrollBack('foot-evaluation-ref')"
      >
        La valutazione online é disponibile esclusivamente per gli immobili
        residenziali <span aria-label=". Torna al testo">&#8617;</span>
      </li>
      <li
        v-if="report"
        id="foot-report"
        role="link"
        tabindex="0"
        class="mb-2 cursor-pointer"
        @click="scrollBack('foot-report-ref')"
        @keyup.enter="scrollBack('foot-report-ref')"
      >
        Il Report Valore Casa è stato redatto per conto di UniCredit RE Services
        da Immobiliare.it, società terza ed indipendente. UniCredit RE Services
        non è responsabile di eventuali danni, perdite o profitti derivanti
        dall'uso improprio dei dati riportati nel presente documento. Si tratta
        di una valutazione commerciale che non sostituisce stime e valutazioni
        peritali. <span aria-label=". Torna al testo">&#8617;</span>
      </li>
      <li
        v-if="ape"
        id="foot-ape"
        role="link"
        tabindex="0"
        class="mb-2 cursor-pointer"
        @click="scrollBack('foot-ape-ref')"
        @keyup.enter="scrollBack('foot-ape-ref')"
      >
        Promozione valida per i clienti che conferiscono un mandato di vendita
        in esclusiva per 12 mesi a UniCredit RE Services entro il 31/12/2025.
        Per APE gratuita si intende APE standard, relativa a immobili
        residenziali fino a 350 mq. Questa promozione è cumulabile con altre
        iniziative.
        <span aria-label=". Torna al testo">&#8617;</span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    evaluation: {
      type: Boolean,
      default: true,
    },

    report: {
      type: Boolean,
      default: true,
    },

    ape: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    numbers() {
      const { evaluation, report, ape } = this

      return evaluation ? report || ape : report && ape
    },
  },
  methods: {
    scrollBack(id) {
      const supEl = document.getElementById(id)

      window.scroll({
        top: supEl.offsetTop,
        behavior: 'smooth',
      })
      supEl.focus()
    },
  },
}
</script>
