<template>
  <SideFormContainer>
    <div class="mb-10">
      <p class="mb-10 text-center text-section-headline font-bold">
        Valuta gratis e vendi casa in modo smart
      </p>
      <GoogleMapsAutocomplete
        ref="GoogleMapsAutocomplete"
        :api-key="$config.googleMapsApiKeyCasavo"
        :required-fields="['streetNumber']"
        @place="autocompleteItem = $event"
      />

      <UcrsButton
        class="w-full"
        type="primary"
        @click="onAutocompleteButtonClick"
      >
        Valuta e vendi
      </UcrsButton>
    </div>

    <Accordion
      :padding="false"
      heading="Vuoi un contatto diretto?"
      content-class="max-h-[800px]"
      @update="
        $tealium.track({
          event_name: 'select_content',
          content_id: 'valuta_vendi_diretto_click',
        })
      "
    >
      <SellForm :title="null" />
    </Accordion>

    <!-- Casavo footer -->
    <div
      slot="footer"
      class="bg-orange-casavo text-black-light rounded-bl-lg rounded-br-[30px] py-4"
    >
      <SvgIcon name="casavo" class="h-7 w-full" />
    </div>
  </SideFormContainer>
</template>

<script>
// Static assets
import { CookieName, CookieRejectValue } from '~/assets/js/cookie'

// Components
import Accordion from '~/components/Accordion.vue'
import UcrsButton from '~/components/UcrsButton.vue'
import SellForm from '~/components/SellForm.vue'
import SideFormContainer from '~/components/SideFormContainer.vue'
import GoogleMapsAutocomplete from '~/components/GoogleMapsAutocomplete.vue'

export default {
  components: {
    Accordion,
    UcrsButton,
    SellForm,
    SideFormContainer,
    GoogleMapsAutocomplete,
  },

  data() {
    return {
      autocompleteItem: null,
    }
  },

  methods: {
    onAutocompleteButtonClick() {
      const validation = this.$refs.GoogleMapsAutocomplete.validate()

      if (!validation.valid) {
        const tealiumErrors = Object.keys(validation.errors).map((it) => ({
          campo: it,
        }))

        this.$tealium.track({
          event_name: 'errori_richiesta',
          errori_richiesta: tealiumErrors,
        })

        return
      }

      const { autocompleteItem, $config } = this

      try {
        this.$tealium.track({
          event_name: 'select_content',
          content_id: 'valuta_vendi_casavo_click',
        })

        // eslint-disable-next-line camelcase
        const claw_uci_enable =
          this.$cookies.get(CookieName) || CookieRejectValue

        // We send also the Google Campaign parameters if they are present in
        // this session.
        const ucid = sessionStorage.getItem('ucid')
        const gclid = sessionStorage.getItem('gclid')

        const url = new URL($config.casavoUrl)
        const params = {
          ...autocompleteItem,
          traffic_source: 'website',
          claw_uci_enable,
          ...(ucid ? { ucid } : {}),
          ...(gclid ? { gclid } : {}),
        }

        for (const it in params) {
          url.searchParams.append(it, params[it])
        }

        window.open(url, '_blank')
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
  },
}
</script>
