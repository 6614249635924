<template>
  <div class="flex flex-col gap-6">
    <SvgIcon
      aria-hidden="true"
      :name="icon"
      :class="[
        'text-blue',
        {
          'w-18.4 h-18.4': iconSize === 'small',
          'w-21.6 h-21.6': iconSize === 'medium',
          'w-28 h-28': iconSize === 'large',
        },
      ]"
    />
    <div>
      <component
        :is="isTitleH2 ? 'h2' : 'h3'"
        v-dompurify-html="title"
        class="paragraph"
      />
    </div>
    <div v-if="content" class="text-lg">
      <Render :content="content" />
    </div>
    <slot name="bottom" />
  </div>
</template>
<script>
export default {
  components: {
    Render: {
      functional: true,
      props: ['content'],
      render: (h, ctx) => ctx.props.content,
    },
  },

  props: {
    layout: {
      type: String,
      default: 'col',
    },

    icon: {
      type: String,
      default: '',
    },

    iconSize: {
      type: String,
      default: 'small',
    },

    title: {
      type: String,
      default: '',
    },

    content: {
      type: Object,
      default: null,
    },
    isTitleH2: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
