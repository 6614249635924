var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-6"},[_c('SvgIcon',{class:[
      'text-blue',
      {
        'w-18.4 h-18.4': _vm.iconSize === 'small',
        'w-21.6 h-21.6': _vm.iconSize === 'medium',
        'w-28 h-28': _vm.iconSize === 'large',
      },
    ],attrs:{"aria-hidden":"true","name":_vm.icon}}),_vm._v(" "),_c('div',[_c(_vm.isTitleH2 ? 'h2' : 'h3',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.title),expression:"title"}],tag:"component",staticClass:"paragraph"})],1),_vm._v(" "),(_vm.content)?_c('div',{staticClass:"text-lg"},[_c('Render',{attrs:{"content":_vm.content}})],1):_vm._e(),_vm._v(" "),_vm._t("bottom")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }